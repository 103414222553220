import './mytable.css';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Select } from 'antd';
import axios from "axios";
import { Switch } from 'antd';

function CompoOrderList(props) {
    console.log('CompoOrderList start');

    async function onClickCancel(orderInfo){
        console.log('onClickCancel', orderInfo);
        const res = await axios.post("/api/cancel_order", {cid: orderInfo.clientOrderId, symbol: orderInfo.symbol});
        if (res.data.success) {
            console.log('cancel ok')
        } else {
            alert('Error:cancel_order:'+res.data.errmsg);
        }
    }

    // async function onClickMarket(orderInfo){
    //     console.log('onClickMarket', orderInfo);
    //     // const res = await axios.post("/api/market_order", {cid: orderInfo.clientOrderId, symbol: orderInfo.symbol});
    //     const res = await axios.post("/api/market_order", orderInfo);
    //     if (res.data.success) {
    //         console.log('market ok')
    //     } else {
    //         alert('Error:market_order:'+res.data.errmsg);
    //     }
    // }

    const render = props.OrderList && props.OrderList.map( function (orderInfo, index) {
        const buttons  =    orderInfo.state==='Completed'? '-' :
                            orderInfo.state==='CANCELED'?  '-' : <Button onClick={()=>onClickCancel(orderInfo)}>Cancel</Button>;
        const side = orderInfo.order_sign>0 ? 'Buy' : 'Sell';
        return (
            <tr key={index}>
                <td>{orderInfo.symbol}</td>
                <td>{orderInfo.curr_price}</td>
                <td>{side}</td>
                <td>{orderInfo.order_price}</td>
                <td>{orderInfo.order_qty.toFixed(4)}</td>
                <td>{orderInfo.executedQty}</td>
                <td>{orderInfo.avgPrice}</td>
                <td>{orderInfo.state}</td>
                {/* <td><Button onClick={()=>onClickCancel(orderInfo)}>Cancel</Button></td> */}
                <td>{buttons}</td>
            </tr>
        )}
    )
    

    // function make_table(orderInfo) {
    //     if (!orderInfo) {
    //         return <></>;
    //     }
    //     else if (!orderInfo.clientOrderId) {
    //         return (
    //             <tr>
    //                 <td>{orderInfo.symbol}</td>
    //                 <td>주문 안함</td>
    //                 <td>{orderInfo.curr_price}</td>
    //                 <td>{orderInfo.order_price}</td>
    //                 <td>{orderInfo.qty}</td>
    //                 <td>{orderInfo.executedQty}</td>
    //                 <td>{orderInfo.avgPrice}</td>
    //             </tr>
    //         )
    //     } else {
    //     }
    // }

    return (
        <div style={{margin:'15px'}}>
            <table className='mytable'>
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>주문시 시장가</th>
                        <th>Side</th>
                        <th>주문 가격</th>
                        <th>qty</th>
                        <th>FilledQty</th>
                        <th>avgPrice</th>
                        <th>State</th>
                        <th>Cancel</th>
                    </tr>
                </thead>
                <tbody>
                    {render}
                </tbody>
            </table>
        </div>
    )
}

export default CompoOrderList
